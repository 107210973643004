<template>
  <div class="feedback_block">
    <div class="feedback_header_block">
      <div class="feedback_header_container">
        <div class="feedback_header_label">
          Обратная связь
        </div>
      </div>
    </div>
    <div class="feedback_main_block">
      <div class="feedback_main_container">
        <div class="feedback_main_form">
          <div class="feedback_main_text">
            <div class="feedback_main_text_choose_themes">
              <div>Тема:</div>
              <div class="feedback_main_text_list_themes">
                Предложения по Уроку и Курсу в целом
              </div>
            </div>
            <textarea class="feedback_main_textarea" v-model="textAreaValue"/>
            <div v-show="textAreaValue.length <= 0" class="feedback_main_textarea_img"/>
          </div>
          <div class="feedback_main_message">
            <div class="feedback_main_message_img"/>
            <div class="feedback_main_message_text">
              <p>В данном разделе Вы можете публиковать Ваши отзывы о портале, а также сможете размещать свои пожелания
                и
                предложения для дальнейшего развития и улучшения портала.</p>
              <p>Все Ваши предложения и замечания будут рассмотрены Администрацией портала, а самые лучшие предложения
                будут
                воплощены в жизнь!</p><br>
              <p>С уважением,<br>
                Администрация Smart oku</p>
            </div>
          </div>
        </div>
        <div class="theme_one_desc_btn">
          <a href="#" class="theme_one_desc_btn_back">Отмена</a>
          <a href="#" class="theme_one_desc_btn_next">Отправить</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  data() {
    return {
      textAreaValue: "",
    }
  }
}
</script>

<style scoped>

</style>